<template>
    <div class="beva-home">
        <div class="openAPP4" @click="openAppAction4">openAPP4</div>

        <!-- <div id="downloadButton">打开app</div> -->
        <wx-open-launch-app id="launch-btn" appid="wxe07f8649f83e7cf0" extinfo="asdfdsaa">
            <script type="text/wxtag-template">
                <style>.btn { padding: 12px }</style>
                    <button class="btn">App内查看</button>
                    </script>
        </wx-open-launch-app>
        <wx-open-launch-weapp id="launch-btn2" appid="wxd20f2b63ea9ce6b7" >
            <script type="text/wxtag-template">
                <style>.btn { padding: 12px }</style>
                    <button class="btn">打开小程序</button>
                  </script>
        </wx-open-launch-weapp>
        <div class="openAPP2" @click="openAppAction2">asdfdasfsafdgfdgfd</div>
        <div class="openAPP3" @click="openAppAction3">cccccccccc</div>
        <div class="openAPP" id="Button1" @click="openAppAction">asfsafds</div>
        <div class="openAPP2" @click="openAppAction2">asdfdasfsafdgfdgfd</div>
        <div class="openAPP3" @click="openAppAction3">cccccccccc</div>
        <div class="openAPP4" @click="openAppAction4">xxxxxxxxx</div>
        <div class="text_cc">
            <van-field v-model="input_value" label="" :placeholder="this.play_horder" class="input_text"
                @focus="becomeFirstCous" @blur="resginFirstCous" :readonly="ccReadonly" />
            <input type="text" name="" id="ccctest" value="sdfasdfasdfsd">
        </div>

    </div>
</template>
<script type="text/javascript" charset="UTF-8" src="https://cdn.xinstall.com/xinstall.js"></script>
<script>
import axios from "axios";
import {
    requestQueryDataTGPMethod,
} from "../utils/appManager";
import Vconsole from 'vconsole'

let vConsole = new Vconsole()
import { wxconfig } from "./wxApi.js"
export default {
    name: "ClassFile",
    components: {

    },
    data() {
        return {
            jssdkTicket: '',
            jssdkTicket2: '',
            input_value: '',
            play_horder: 'ccc',
            ccReadonly: false,
        };
    },
    methods: {
        getInstallState(options) {
        return WeixinJSBridge.invoke('getInstallState', options, res => {
        console.log(res)
        });
    },
        loadStartAction(){

        },
        installThirdAction(){
            var data = XInstall.parseUrlParams(); //xinstall.js中提供的工具函数，解析url中的所有查询参数，函数返回格式为js Object
        new XInstall({
            /*xinstall中应用的唯一标识，用于识别您的应用*/
            appKey: "eltafks",
            buttonId: [{ idName: 'Button1', params: {dataA: 'action%3A9999%3Ftitle%3D%E6%96%87%E7%AB%A0%E8%AF%A6%E6%83%85%26%26fileName%3DArticleDetail%26%26firstLoad%3D%7B%22id%22%3A15433%7D', da: '1'} }],
            // 8l4hkz1
            // appKey: "8l4hkz1",
            /*—————————————————分割线——————————————————*/
            /*附加功能1：特殊情况无法直接跳转下载时，引导打开三方浏览器下载的遮罩层可用以下方法自定义样式*/
            //mask:function(){
            //  return "<div style='background:rgba(0,0,0,.5);position:fixed;left:0;top:0;filter:alpha(opacity=50);width:100%;height:100%;z-index:9999;'><div style='text-align:right; margin-top:1%; margin-right:5%;'><img style='width:70%; margin:0 auto;' src='https://cdn.xinstall.com/image/no_safari.png'/></div></div>"
            //},
            /*—————————————————分割线——————————————————*/
            /*xinstall初始化完成的回调函数*/
            onready: function() {
                var m = this,
                    button = document.getElementById("downloadButton");
                console.log(button);
                /*用户点击您页面上的某个按钮时(假定您的按钮id为downloadButton)，安装app*/
                /*
                  警告：
                  1、被绑定使用xinstall下载拉起的id，不需要额外再单独写下载相关的处理，如使用a标签href另外下载或者使用js绑定点击使用额外的下载，这样会导致拉起和数据传递失效！！！
                  2、按钮id值请勿与附加功能2中重复使用，否则将导致功能失效！！
                */
                button.onclick = function() {
                    console.log("ccc");
                    m.wakeupOrInstall();
                }
            }
        }, data);
        },
        openAppAction(){
            console.log("ccc");
            var options = {packageName:'com.company.listenStock',packageUrl:'scxlistenstock://'};
            this.getInstallState(options);
            // window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.jd.jdlite&android_schema=openjdlite%20";
        },
        openAppAction2(){
            // console.log("https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock&android_schema=scxlistenstock%3A%2F%2F");
            // window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock&ios_schema=scxListenStock%3A%2F%2F";
            
        },
        openAppAction3(){
            // window.location.href = "https://api.tinggupiao.com.cn/ccc";
            // window.location.href = "scxListenStock://";
        },
        openAppAction4(){
           
        },
        getjssdkTicket() {
            requestQueryDataTGPMethod(
                "v4/wechat/getJssdk",
                { jsApiList: ['launchApplication', 'getInstallState'], url: window.location.href, openTagList: ['wx-open-launch-app','wx-open-launch-app'] },
                "getjssdkTicketSuccess",
                "0",
                "post"
            );
        },
        becomeFirstCous() {

        },
        resginFirstCous() {
        },
        
    },
    mounted() {
        document.addEventListener("WeixinOpenTagsError", function(t) {
            console.log("cccc");
            alert(t.detail.errMsg);
        });
        this.getjssdkTicket();
        var button = document.getElementById("downloadButton");
        console.log(button);
        this.installThirdAction();
        var btn = document.getElementById('launch-btn2');
        btn.addEventListener('launch', function (e) {
            console.log('success');
            console.log(e);
            console.log(e.detail);
        });
        btn.addEventListener('error', function (e) {
            console.log('fail', e.detail);
        });



    },
    created() {
        window["getjssdkTicketSuccess"] = (data) => {
            console.log(data);
            this.jssdkTicket = data.data.signature;
            wxconfig(this.jssdkTicket, data.data.timestamp, data.data.url, data.data.nonceStr, data.data.appId);
        }
        window["getTicket2Success"] = (data) => {
            console.log(data);
            this.jssdkTicket2 = data;
            console.log("cccccc");
            wxconfig(this.jssdkTicket, this.jssdkTicket2);
        }

    }
};
</script>
<style scoped  lang="scss" >
#downloadButton {
    height: 50px;
    background-color: orange;
}

.openAPP {
    margin-left: 100px;
    margin-top: 100px;
    height: 100px;
    background-color: purple;

}

.openAPP2 {
    margin-left: 200px;
    margin-top: 100px;
    height: 100px;
    background-color: cyan;

}

.openAPP3 {
    display: inline-block;
    margin-left: 0;
    margin-top: 100px;
    height: 100px;
    width: 100px;
    background-color: yellow;
}

.openAPP4 {
    margin-left: 0;
    margin-top: 0;
    width: 100px;
    height: 100px;
    background-color: blue;
}

.btn {
    display: flex;
    align-items: center;
    border-radius: 50px;
    font-size: 15px;
    color: #ffffff;
    font-weight: 700;
    padding: 0 50px;
    height: 45px;
    line-height: 45px;
    background-color: #FF9700;
    margin: 0 auto;
}

.text_cc {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100px;
    width: 200px;
    background-color: red;

    input {
        width: 300px;
        height: 50px;
        background-color: green;
    }
}
</style>